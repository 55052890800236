<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

            <aside-main/>

            <header-main/>

            <div class="content">

                <div class="card-header mb-5 d-flex flex-between-center">
                    <div class="d-flex align-items-center">
                        <h2 class="text-bold text-1100">Компании</h2>
                    </div>

                    <div class="d-flex">
                        <router-link
                            :to="{name: 'CampaignsCreate'}"
                            class="btn btn-primary me-1 mb-1 float_right">
                            Создать
                        </router-link>
                    </div>
                </div>


                <div class="row align-items-center justify-content-between g-3 mb-3">
                    <div class="col-sm-6 col-md-4">
                        <div class="form-icon-container">
                            <input
                                @input="getItems"
                                v-model="search.name"
                                type="text" 
                                placeholder="Название" 
                                class="form-control form-icon-input">
                            <span class="fa-regular fa-magnifying-glass text-500 fs--1 form-icon"></span>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4">
                        <div class="form-icon-container">
                            <vue-select
                                @input="getItems"
                                v-model="search.type"
                                :options="utils.types"
                                placeholder="Тип компании"
                                label="name">
                                <template v-slot:no-options="{ search, searching }">
                                  <template v-if="searching">
                                     Ничего не найдено по запросу <em>{{ search }}</em>.
                                  </template>
                                </template>
                            </vue-select>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4">
                        <div class="form-icon-container">
                            <vue-select
                                @input="getItems"
                                v-model="search.status"
                                :options="utils.statuses"
                                placeholder="Статус"
                                label="name">
                                <template v-slot:no-options="{ search, searching }">
                                  <template v-if="searching">
                                     Ничего не найдено по запросу <em>{{ search }}</em>.
                                  </template>
                                </template>
                            </vue-select>
                        </div>
                    </div>
                </div>


                <div class="card-body p-0 spinner-center" v-if="preloader">
                    <div class="p-4 code-to-copy">
                        <div class="spinner-border" role="status"></div>
                    </div>
                </div>
                

                <div class="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-white border-y border-300 mt-2 position-relative top-1" v-if="!preloader">
                    <div class="table-responsive scrollbar ms-n1 ps-1" style="padding-bottom: 5px">
                        <div class="table table-responsive-small">
                            <datatable
                                :columns="columns" 
                                :data="items">
                                <template scope="{ row, columns }">
                                    <tr>
                                        <td>{{ row.id }}</td>
                                        <td>{{ row.name }}</td>
                                        <td>{{ utils.types.find(el => el.key == row.type).name }}</td>
                                        <td>
                                            <template v-if="row.status == 0">
                                                <span class="badge badge-casino fs--3 badge-casino-warning">
                                                    {{ utils.statuses.find(el => el.key == row.status).name }}
                                                    <i class="fa-regular fa-clock"></i>
                                                </span>
                                            </template>
                                            <template v-else-if="row.status == 1">
                                                <span class="badge badge-casino fs--3 badge-casino-success">
                                                    {{ utils.statuses.find(el => el.key == row.status).name }}
                                                    <i class="fa-solid fa-check"></i>
                                                </span>
                                            </template>
                                            <template v-else-if="row.status == 2">
                                                <span class="badge badge-casino fs--3 badge-casino-danger">
                                                    {{ utils.statuses.find(el => el.key == row.status).name }}
                                                    <i class="fa-solid fa-xmark"></i>
                                                </span>
                                            </template>
                                            <template v-else-if="row.status == 3">
                                                <span class="badge badge-casino fs--3 badge-casino-danger">
                                                    {{ utils.statuses.find(el => el.key == row.status).name }}
                                                    <i class="fa-solid fa-xmark"></i>
                                                </span>
                                            </template>
                                        </td>
                                        <td>{{ $moment(row.created_at).format('YYYY-MM-DD H:mm:ss') }}</td>
                                        <td>
                                            <button
                                                v-if="row.status == 1 && 1==2"
                                                @click="$modal.show('generate-land'), setCampaign(row), land = null"
                                                content="Лендинг" 
                                                v-tippy="{arrow : true, arrowType: 'round'}"
                                                type="button"
                                                class="btn btn-soft-secondary btn-sm me-1 mb-1">
                                                <i class="fa-light fa-globe"></i>
                                            </button>
                                            <button
                                                :disabled="row.type == 3"
                                                @click="$modal.show('item-telegram'), setCampaignTelegramWebApp(row)"
                                                type="button"
                                                class="btn btn-soft-secondary btn-sm me-1 mb-1">
                                                <i class="fa-brands fa-telegram"></i>
                                            </button>
                                            <button
                                                :disabled="row.type == 3"
                                                v-if="row.status == 1"
                                                @click="$modal.show('item-link'), setCampaign(row)"
                                                type="button"
                                                class="btn btn-soft-secondary btn-sm me-1 mb-1">
                                                <i class="fa-regular fa-link"></i>
                                            </button>
                                            <button
                                                :disabled="![1,2].includes(row.status)"
                                                @click="changeStatus(row.id)"
                                                :content="row.status == 1 ? 'Отключить' : 'Включить'" 
                                                v-tippy="{arrow : true, arrowType: 'round'}"
                                                :class="{'disabled': status_item_preloader}"
                                                class="btn btn-soft-secondary btn-sm me-1 mb-1">
                                                <span class="spinner-border spinner-border-sm spinner-border-custom-small" role="status" v-if="status_item_preloader && status_item_preloader == row.id"></span>
                                                <i
                                                    v-if="!status_item_preloader || status_item_preloader != row.id"
                                                    :class="{'fa-circle-pause': row.status == 1, 'fa-play': row.status != 1}"
                                                    class="fa-regular"></i>
                                            </button>
                                            <router-link
                                                :to="{name: 'CampaignsItem', params: {id: row.id}}"
                                                content="Редактировать" 
                                                v-tippy="{arrow : true, arrowType: 'round'}"
                                                class="btn btn-soft-primary btn-sm me-1 mb-1">
                                                <i class="fa-regular fa-pen-to-square"></i>
                                            </router-link>
                                        </td>
                                    </tr>
                                </template>

                                <template name="no-result">
                                    Ничего не найдено
                                </template>
                            </datatable>
                        </div>
                    </div>
                </div>


                <!-- Modals -->
                <modal 
                    name="item-telegram"
                    height="auto"
                    :shiftY="0.3"
                    :adaptive="true">
                    <div class="row g-3">
                        <div class="col-sm-12 col-md-12">
                            <div class="form-floating">
                                <input
                                    disabled
                                    v-model="link_telegram_webapp"
                                    placeholder="Ссылка"
                                    class="form-control" 
                                    type="text"/>
                                <label>Ссылка</label>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-floating v-select-medium">
                                <vue-select
                                    class="current_telegram_webapp"
                                    @input="changeTelegramWebapp()"
                                    v-model="current_telegram_webapp"
                                    :options="utils.telegram_webapp" 
                                    placeholder="WebApp"
                                    label="name">
                                    <template v-slot:no-options="{ search, searching }">
                                      <template v-if="searching">
                                         Ничего не найдено по запросу <em>{{ search }}</em>.
                                      </template>
                                    </template>
                                </vue-select>
                                <label>WebApp</label>
                            </div>
                        </div>
                        <div class="col-sm-4 col-md-4">
                            <button class="btn btn-primary px-5 w-100 text-nowrap" type="button" @click="copyLinkTelegramWebapp">
                                <span>Скопировать</span>
                            </button>
                        </div>
                    </div>
                </modal>

                <modal 
                    name="item-link"
                    height="auto"
                    :shiftY="0.3"
                    :adaptive="true">
                    <div class="row g-3" v-on:submit.prevent="createItem">
                        <div class="col-sm-12 col-md-12">
                            <div class="form-floating">
                                <input
                                    v-model="link"
                                    placeholder="Ссылка"
                                    class="form-control" 
                                    type="text"/>
                                <label>Ссылка</label>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-floating v-select-medium">
                                <vue-select
                                    @input="changeLink()"
                                    v-model="mirror"
                                    :options="utils.mirrors" 
                                    placeholder="Домен"
                                    label="name">
                                    <template v-slot:no-options="{ search, searching }">
                                      <template v-if="searching">
                                         Ничего не найдено по запросу <em>{{ search }}</em>.
                                      </template>
                                    </template>
                                </vue-select>
                                <label>Домен</label>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-floating v-select-medium">
                                <vue-select
                                    @input="changeLink()"
                                    v-model="lang"
                                    :options="utils.languages" 
                                    placeholder="Язык"
                                    label="name">
                                    <template v-slot:no-options="{ search, searching }">
                                      <template v-if="searching">
                                         Ничего не найдено по запросу <em>{{ search }}</em>.
                                      </template>
                                    </template>
                                </vue-select>
                                <label>Язык</label>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-floating v-select-medium">
                                <vue-select
                                    @input="changeLink()"
                                    v-model="page"
                                    :options="utils.pages" 
                                    placeholder="Страница"
                                    label="name">
                                    <template v-slot:no-options="{ search, searching }">
                                      <template v-if="searching">
                                         Ничего не найдено по запросу <em>{{ search }}</em>.
                                      </template>
                                    </template>
                                </vue-select>
                                <label>Страница</label>
                            </div>
                        </div>
                        <div class="col-sm-4 col-md-4">
                            <button class="btn btn-primary px-5 w-100 text-nowrap" type="button" @click="copyLink">
                                <span>Скопировать</span>
                            </button>
                        </div>
                    </div>
                </modal>

                <modal 
                    name="generate-land"
                    height="auto"
                    :shiftY="0.3"
                    :adaptive="true">
                    <div class="row g-3">
                        <div class="col-sm-12 col-md-12">
                            <div class="form-floating v-select-medium">
                                <vue-select
                                    @input="changeLand()"
                                    v-model="land"
                                    :options="utils.landers" 
                                    placeholder="Лендинг"
                                    label="name">
                                    <template v-slot:no-options="{ search, searching }">
                                      <template v-if="searching">
                                         Ничего не найдено по запросу <em>{{ search }}</em>.
                                      </template>
                                    </template>
                                </vue-select>
                                <label>Лендинг</label>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12" v-if="land">
                            <div class="form-floating">
                                <input
                                    v-model="link_land"
                                    placeholder="Ссылка"
                                    class="form-control" 
                                    type="text"/>
                                <label>Ссылка</label>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12" v-if="land">
                            <div class="form-floating v-select-medium">
                                <vue-select
                                    @input="changeLangLand()"
                                    v-model="lang"
                                    :options="utils.languages" 
                                    placeholder="Язык"
                                    label="name">
                                    <template v-slot:no-options="{ search, searching }">
                                      <template v-if="searching">
                                         Ничего не найдено по запросу <em>{{ search }}</em>.
                                      </template>
                                    </template>
                                </vue-select>
                                <label>Язык</label>
                            </div>
                        </div>

                        <div class="col-sm-4 col-md-4" v-if="land">
                            <button class="btn btn-primary px-5 w-100 text-nowrap" type="button" @click="copyLinkLand">
                                <span>Скопировать</span>
                            </button>
                        </div>
                    </div>
                </modal>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>
export default {
    data () {
        return {
            preloader: true,
            status_item_preloader: false,
            selected_campaign: null,
            current_domain: null,
            current_telegram_webapp: null,
            land_domain: null,
            link: null,
            link_land: null,
            link_telegram_webapp: null,
            land: null,
            lang: null,
            mirror: null,
            page: {
                name: 'Главная',
                key: 'home'
            },
            items: [],
            columns: [
                {label: 'ID'},
                {label: 'Название'},
                {label: 'Тип'},
                {label: 'Статус'},
                {label: 'Дата'}
            ],
            search: {
                search_change: null,
                name: null,
                type: null,
                status: null
            },
            utils: {
                languages: [],
                landers: [],
                mirrors: [],
                telegram_webapp: [],
                pages: [
                    {
                        name: 'Главная',
                        key: 'home'
                    },
                    {
                        name: 'Регистрация',
                        key: 'signup'
                    },
                    {
                        name: 'Авторизация',
                        key: 'login'
                    },
                ],
                types: [
                    {
                        name: 'Revshare',
                        key: 1
                    },
                    {
                        name: 'CPA',
                        key: 2
                    },
                    {
                        name: 'Персональный Промокод',
                        key: 3
                    }
                ],
                statuses: [
                    {
                        name: 'Модерация',
                        key: 0
                    },
                    {
                        name: 'Включена',
                        key: 1
                    },
                    {
                        name: 'Отключена',
                        key: 2
                    },
                    {
                        name: 'Отклонена',
                        key: 3
                    },
                ]
            }
        }
    },
    methods: {
        getItems () {
            this.preloader = true
            this.$axios.post('/campaigns/items', JSON.stringify({
                search: {
                    name: this.search.name,
                    type: this.search.type != null ? this.search.type.key : null,
                    status: this.search.status != null ? this.search.status.key : null
                }
            }))
            .then((response) => {
                this.preloader = false
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        this.items = response.data.items
                        this.current_domain = response.data.domain
                        this.land_domain = response.data.land_domain

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })     
        },
        getLanders () {
            this.preloader = true
            this.$axios.post('/landers/items')
            .then((response) => {
                this.preloader = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.utils.landers = response.data

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                this.preloader = false
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })
        },
        getLanguages() {
            this.$axios.post('/utils/languages/items')
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":

                        for (let item in response.data) {
                            this.utils.languages.push({
                                name: response.data[item],
                                key: item,
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })
        },
        getMirrors() {
            this.$axios.post('/utils/mirrors/items')
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":

                        for (let item of response.data) {
                            this.utils.mirrors.push({
                                name: item.domain,
                                key: item.domain,
                            })
                        }

                        for (let item of response.telegram_webapp) {

                            if (!this.current_telegram_webapp) {
                                this.current_telegram_webapp = {
                                    name: item.url,
                                    url: item.url,
                                }
                            }

                            this.utils.telegram_webapp.push({
                                name: item.url,
                                url: item.url,
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })
        },
        changeStatus (id) {
            this.status_item_preloader = id
            this.$axios.post(`/campaigns/changestatus/${id}`)
            .then((response) => {
                this.status_item_preloader = false
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        let item_index = this.items.findIndex(row => row.id == id)
                        this.items[item_index].status = this.items[item_index].status == 1 ? 2 : 1

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)
                
                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        copyLink () {
            navigator.clipboard.writeText(this.link)
            this.$toast.success('Скопировано')
        },
        copyLinkLand () {
            navigator.clipboard.writeText(this.link_land)
            this.$toast.success('Скопировано')
        },
        copyLinkTelegramWebapp () {
            navigator.clipboard.writeText(this.link_telegram_webapp)
            this.$toast.success('Скопировано')
        },
        setCampaign (row) {
            this.selected_campaign = row
            this.link = `https://${this.current_domain}?ref_id=${this.selected_campaign.id}&click_id={SID}`
        },
        /*setCampaignTelegramWebApp (row) {
            this.selected_campaign = row
            this.link_telegram_webapp = `${this.current_telegram_webapp.url}?startapp=${this.selected_campaign.id}`
        },*/
        setCampaignTelegramWebApp (row) {
            this.selected_campaign = row
            this.link_telegram_webapp = this.link = `https://${this.current_domain}?ref_id=${this.selected_campaign.id}&tg=${btoa(this.current_telegram_webapp.url)}`
        },
        changeLand () {
            if(this.land) {
                this.link_land = `https://${this.land_domain}?land=${this.land.slug}&ref_id=${this.selected_campaign.id}&click_id={SID}`
            }
        },
        changeLangLand () {
            if(this.lang) {
                this.link_land = `https://${this.land_domain}?land=${this.land.slug}&ref_id=${this.selected_campaign.id}}&lang=${this.lang.key}&click_id={SID}`
            } else {
                this.link_land = `https://${this.land_domain}?land=${this.land.slug}&ref_id=${this.selected_campaign.id}&click_id={SID}`
            }
        },
        changeLink () {
            var params = {
                ref_id: this.selected_campaign.id,
                //click_id: '{SID}'
            }

            if (this.lang) {
                params.lang = this.lang.key
            }

            if (this.mirror) {
                params.domain = this.mirror.key
            }

            if (this.page && this.page.key != 'home') {
                params.page = this.page.key
            }

            this.link = `https://${this.current_domain}?` + new URLSearchParams(params).toString()
        },
        /*changeTelegramWebapp () {
            var params = {
                startapp: this.selected_campaign.id
            }

            this.link_telegram_webapp = `${this.current_telegram_webapp.url}?` + new URLSearchParams(params).toString()
        }*/
        changeTelegramWebapp () {
            var params = {
                ref_id: this.selected_campaign.id,
                tg: btoa(this.current_telegram_webapp.url)
            }

            this.link = `https://${this.current_domain}?` + new URLSearchParams(params).toString()
        }
    },
    beforeMount() {
        this.getItems()
        this.getLanguages()
        this.getLanders()
        this.getMirrors()
    }
}
</script>

<style lang="css" scoped>
    ::v-deep .current_telegram_webapp .vs__clear {
        display: none;
    }
</style>